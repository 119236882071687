import html from "../assets/icons/icons8-html-5.svg";
import css from "../assets/icons/icons8-css3.svg";
import jquery from "../assets/icons/icons8-jquery.svg";
import node from "../assets/icons/icons8-node-js.svg";
import reactIcon from "../assets/icons/icons8-react.svg";
import javaScript from "../assets/icons/icons8-javascript.svg";
import arduino from "../assets/icons/icons8-arduino.svg";
import nextJs from "../assets/icons/icons8-nextjs.svg";
import photoShop from "../assets/icons/icons8-photoshop.svg";
import blogger from "../assets/icons/icons8-blogger.svg";
import canva from "../assets/icons/icons8-canva.svg";

const technologies = [
  {
    name: "Arduino",
    url: arduino,
    alt: "Arduino Icon",
    percentage: "90%",
  },
  {
    name: "HTML",
    url: html,
    alt: "HTML Icon",
    percentage: "95%",
  },
  {
    name: "CSS",
    url: css,
    alt: "CSS Icon",
    percentage: "90%",
  },
  {
    name: "jQuery",
    url: jquery,
    alt: "Javascript Icon",
    percentage: "70%",
  },
  {
    name: "Node Js",
    url: node,
    alt: "Node js Icon",
    percentage: "40%",
  },
  {
    name: "React",
    url: reactIcon,
    alt: "React Icon",
    percentage: "70%",
  },
  {
    name: "JavaScript",
    url: javaScript,
    alt: "JavaScript Icon",
    percentage: "90%",
  },
  {
    name: "Nextjs",
    url: nextJs,
    alt: "Nextjs Icon",
    percentage: "50%",
  },
  {
    name: "Photoshop",
    url: photoShop,
    alt: "Photoshop Icon",
    percentage: "50%",
  },
  {
    name: "Blogger",
    url: blogger,
    alt: "Blogger Icon",
    percentage: "75%",
  },
  {
    name: "Canva",
    url: canva,
    alt: "Canva Icon",
    percentage: "60%",
  },
];

export default technologies;
