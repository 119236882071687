import webButtonsGen from "../assets/projects-images/generador-de-botones-css.png";
import edeptec from "../assets/projects-images/edeptec.png";
import ahorcado from "../assets/projects-images/juego del ahorcado.png";
import toDoList from "../assets/projects-images/to-do-list-vanilla-js.jpg";
import arduinoProjects from "../assets/projects-images/Proyectos con arduino portfolio img.jpg";
import picProjects from "../assets/projects-images/Proyectos con pic portfolio img.jpg";
import visualBasic from "../assets/projects-images/proyectos-visual-basic-portfolio.jpg";
import gallery from "../assets/projects-images/Galeria de diseños.png";
import traslator from "../assets/projects-images/clave-murcielago-a-español.jpg";
import passWordGenerator from "../assets/projects-images/password-generator-interface-2.jpg";
import friendsCardsGame from "../assets/projects-images/friends-cards-game.jpg";

// icons

import html from "../assets/icons/icons8-html-5.svg";
import css from "../assets/icons/icons8-css3.svg";
import jquery from "../assets/icons/icons8-jquery.svg";
import reactIcon from "../assets/icons/icons8-react.svg";
import javaScript from "../assets/icons/icons8-javascript.svg";
import arduino from "../assets/icons/icons8-arduino.svg";
import nextJs from "../assets/icons/icons8-nextjs.svg";
import photoShop from "../assets/icons/icons8-photoshop.svg";
import blogger from "../assets/icons/icons8-blogger.svg";
import canva from "../assets/icons/icons8-canva.svg";
import visualbasic from "../assets/icons/icons8-visual-basic-96.png";
import bluetooth from "../assets/icons/icons8-bluetooth-60.png";

const projectsEsDb = [
  {
    title: "Generador de Botones para web",
    text: "Generador de estilos para botones web.",
  },
  {
    title: "EDEPTEC",
    text: "Blog personal alojado en blogger.",
  },
  {
    title: "Juego del ahorcado",
    text: "Juego del ahorcado, desarrollado con React JS",
  },
  {
    title: "To Do List",
    text: "To Do List. Con almacenamiento de tareas en local storage.",
  },
  {
    title: "Galeria de diseños",
    text: "Galeria de diseños realizados con photoshop y canva.",
  },
  {
    title: "Traductor de clave murcielago",
    text: "Traductor de clave murcielago a español y viceversa.",
  },
  {
    title: "Generador de Contraseñas",
    text: "Generador de contraseñas seguras.",
  },
  {
    title: "Friends Cards Juego",
    text: "Adaptacion web del juego verdad o reto.",
  },
];

const projectsEnDb = [
  {
    title: "Web Button Generator",
    text: "Style generator for web buttons.",
  },
  {
    title: "EDEPTEC",
    text: "Personal blog hosted on Blogger.",
  },
  {
    title: "Hangman Game",
    text: "Hangman game developed with React JS.",
  },
  {
    title: "To Do List",
    text: "To Do List with task storage in local storage.",
  },
  {
    title: "Design Gallery",
    text: "Gallery of designs made with Photoshop and Canva.",
  },
  {
    title: "Murcielago Code Translator",
    text: "Translator for Murcielago code to Spanish and vice versa.",
  },
  {
    title: "Password Generator",
    text: "Secure password generator.",
  },
  {
    title: "Friends Cards Game",
    text: "Web adaptation of the Truth or Dare game.",
  },
];
const projectsDb = [
  {
    src: webButtonsGen,
    techStack: [html, css, javaScript, jquery],
    url: "https://buttongen.edeptec.com/",
  },
  {
    src: edeptec,
    techStack: [
      html,
      css,
      javaScript,
      jquery,
      blogger,
      photoShop,
      canva,
      arduino,
    ],
    url: "https://www.edeptec.com",
  },
  {
    src: ahorcado,
    techStack: [reactIcon],
    url: "https://ahorcado.edeptec.com",
  },
  {
    src: toDoList,
    techStack: [html, css, javaScript],
    url: "https://estebancarrillog.github.io/to-do-list",
  },
  {
    src: gallery,
    techStack: [html, css, javaScript],
    url: "https://estebancarrillog.github.io/designs-gallery",
  },
  {
    src: traslator,
    techStack: [reactIcon],
    url: "https://clavemurcielago.edeptec.com/",
  },
  {
    src: passWordGenerator,
    techStack: [html, css, javaScript],
    url: "https://passwordgenerator.edeptec.com/",
  },
  {
    src: friendsCardsGame,
    techStack: [nextJs],
    url: "https://friendscards.edeptec.com/",
  },
];

const projectsListsEsDb = [
  {
    title: "Proyectos en Visual Basic 6.0",
    text: "Coleccion de proyectos realizados con Visual Basic programados en Basic",
  },
  {
    title: "Proyectos con Arduino",
    text: "Coleccion de proyectos realizados con arduino programados en C++",
  },
  {
    title: "Proyectos con PIC",
    text: "Coleccion de proyectos realizados con pic programados en Basic",
  },
];

const projectsListsEnDb = [
  {
    title: "Projects in Visual Basic 6.0",
    text: "Collection of projects made with Visual Basic, programmed in Basic.",
  },
  {
    title: "Projects with Arduino",
    text: "Collection of projects made with Arduino, programmed in C++.",
  },
  {
    title: "Projects with PIC",
    text: "Collection of projects made with PIC, programmed in Basic.",
  },
];

const projectsListsDb = [
  {
    src: visualBasic,
    techStack: [visualbasic],
    url: "https://www.edeptec.com/p/proyectos-con-visual-basic.html",
  },
  {
    src: arduinoProjects,
    techStack: [arduino, bluetooth],
    url: "https://www.edeptec.com/p/proyectos-con-arduino.html",
  },
  {
    src: picProjects,
    url: "https://www.edeptec.com/p/proyectos-con-pic.html",
  },
];

export {
  projectsDb,
  projectsListsDb,
  projectsEsDb,
  projectsEnDb,
  projectsListsEsDb,
  projectsListsEnDb,
};
