import { useSelector } from "react-redux";
import "../stylesheets/Project.css";
import CtaProjectButton from "./CtaProjectButton";
import Icon from "./Icon";

function Project({
  src,
  title,
  url,
  techStack = [],
  text,
  buttonText = "Ver proyecto",
}) 
{

  const data = useSelector(state => state.lang.data);
  return (
    <div className="project-item">
      <figure className="project-item-figure">
        <img src={src} alt="" />
      </figure>
      <div className="project-item-content">
        <h3>{title}</h3>
        <p>{text}</p>
        {techStack.length > 0 ? <h4>{data.projects.type?.text}</h4> : ""}
        <div className="project-tech-stack-container">
          {techStack.map((tech) => (
            <Icon key={tech} src={tech} />
          ))}
        </div>
        <CtaProjectButton url={url} text={buttonText} />
      </div>
    </div>
  );
}

export default Project;
