import"../stylesheets/Icon.css"

function Icon({name=null,src, alt=" ",item}) {
    return(
        <figure className={` ${item === name ? "icon-figure icon-figure--active" :"icon-figure "} `}>
            <img src={src} alt={alt}/>
        </figure>
    )
    
}

export default Icon