import { useSelector } from "react-redux";
import "../stylesheets/Nav.css";
import NavItemButton from "./NavItemButton";
import SocialIcons from "./SocialIcons";

function Nav() {
  const data = useSelector((state) => state.lang.data);
  const navLocations = ["#about", "#skills", "#projects", "#certificates"];
  return (
    <nav className="nav">
      <div className="nav__container container">
        <input type="checkbox" id="menu" className="nav__input" />
        <label className="nav__toggle" htmlFor="menu">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </label>
        <p className="nav__title">Esteban Carrillo</p>

        <div className="nav__content">
          <ul className="nav__list container">
            {navLocations.map((location, i) => (
              <li key={location} className="nav__item">
                <NavItemButton text={data.nav?.links[i]} location={location} />
              </li>
            ))}
          </ul>

          <div className="nav__media container">
            <SocialIcons />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
