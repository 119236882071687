import { useSelector } from "react-redux";
import "../stylesheets/Hero.css";
import AnimatedWord from "./AnimatedWords";

function Hero({ className, trigger }) {
  const data = useSelector((state) => state.lang.data);

  if (!data.hero) {
    return (
      <section className={className} id="hero">
        Loading...
      </section>
    );
  }

  return (
    <section className={className}>
      <div className="hero-content container">
        <p className="hero-title">
          {data.hero.title} <br />
          {trigger ? (
            <AnimatedWord
              addContainer={true}
              words={data.hero.words}
              delay={150}
            />
          ) : (
            ""
          )}{" "}
        </p>
        <p className="hero-paragraph">{data.hero.paragraph}</p>
        <a className="hero-cta" href="#about">
          {data.hero.ctaButton}
        </a>
      </div>
    </section>
  );
}

export default Hero;
