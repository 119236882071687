import { projectsEnDb, projectsEsDb, projectsListsEnDb, projectsListsEsDb } from "./projectsDatabase";

const spanish = {
  nav: {
    links: ["Sobre mi", "Skills", "Proyectos", "Certificados"],
  },
  hero: {
    title: "Hola, soy Esteban Carrillo",
    words: [
      "tecnólogo en electrónica.    ",
      "programador.   ",
      "autodidacta.    ",
      "ciclista aficionado.   ",
      
    ],
    paragraph: "Bienvenido a mi portfolio.",
    ctaButton: "Sobre mi",
  },

  about: {
    title: "Acerca de mi",
    text: [
      "Soy tecnólogo en electrónica, con una pasión inquebrantable por la innovación y un compromiso sólido con la exploración continua. Mi carrera se ha forjado sobre una base de curiosidad insaciable y una habilidad para transformar el conocimiento en soluciones prácticas y efectivas.",
      "La programación es mi herramienta creativa, donde convierto ideas en soluciones tangibles que generan un impacto real. A lo largo de mi trayectoria, he superado desafíos técnicos y he demostrado una evolución constante en mi campo.",
      "Más allá del ámbito tecnológico, el ciclismo y el running son pilares fundamentales en mi vida. Estas actividades no solo me mantienen activo, sino que también refuerzan mi creencia en la importancia de un equilibrio entre el intelecto y el bienestar físico.",
      "Me guío por el lema: 'Learn, teach, run and ride'. Creo profundamente en el aprendizaje continuo, en compartir conocimientos y en la búsqueda de una vida equilibrada y significativa.",
    ],
  },

  projects: {
    title: "Proyectos",
    type: {
      text: "Tecnologías usadas:",
      computing: {
        title: "Informática",
        projectsText: projectsEsDb,
        btnText: "Ver proyecto",
      },
      electronics: {
        title: "Electrónica",
        projectsText: projectsListsEsDb,
        btnText: "Ver lista de proyectos",
      },
    },
  },
  certificates: {
    title: "Certificados",
  },
  footer: {
    social: {
      title: "Mis redes sociales",
    },
    quickLinks: {
      title: "Enlaces rápidos",
      about: "Sobre mi",
      skills: "Skills",
      projects: "Proyectos",
    },
    contact: "Contacto",
    copyright: "Todos los derechos reservados",
  },
};

const english = {
  nav: {
    links: ["About me", "Skills", "Projects", "Certificates"],
  },
  hero: {
    title: "Hi, I'm Esteban Carrillo",
    words: [
      "electronics technologist.   ",
      "programmer.   ",
      "self-taught.   ",
      "cycling enthusiast.   ",
      
    ],
    paragraph: "Welcome to my portfolio.",
    ctaButton: "About me",
  },
  about: {
    title: "About me",
    text: [
      "I am an electronics technologist with an unbreakable passion for innovation and a strong commitment to continuous exploration. My career has been built on a foundation of insatiable curiosity and the ability to transform knowledge into practical and effective solutions.",
      "Programming is my creative tool, where I turn ideas into tangible solutions that create a real impact. Throughout my journey, I have overcome technical challenges and demonstrated constant growth in my field.",
      "Beyond the tech world, cycling and running are essential pillars in my life. These activities not only keep me active but also reinforce my belief in the importance of a balance between intellect and physical well-being.",
      "I am guided by the motto: 'Learn, teach, run, and ride.' I deeply believe in continuous learning, in sharing knowledge, and in the pursuit of a balanced and meaningful life.",
    ],
  },
  projects: {
    title: "Projects",
    type: {
      text: "Technologies used:",
      computing: {
        title: "Computing",
        projectsText: projectsEnDb,
        btnText: "View project",
      },
      electronics: {
        title: "Electronics",
        projectsText: projectsListsEnDb,
        btnText: "View project list",
      },
    },
  },
  certificates: {
    title: "Certificates",
  },
  footer: {
    social: {
      title: "My social networks",
    },
    quickLinks: {
      title: "Quick links",
      about: "About me",
      skills: "Skills",
      projects: "Projects",
    },
    contact: "Contact",
    copyright: "All rights reserved",
  },
};

export { spanish, english };
