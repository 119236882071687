import { useSelector } from "react-redux";
import SocialIcons from "./SocialIcons";
import("../stylesheets/Footer.css");


export function Footer() {
  const data = useSelector(state => state.lang.data);
  if (!data.footer) {
    return <footer id="footer">Loading...</footer>;
  }
  return (
    <footer>
      <div className="container">
        <div className="footer-social">
          <h3>{data.footer.social.title}</h3>
          <div className="social-icons">
            <SocialIcons />
          </div>
        </div>

        <div>
          <h3>{data.footer.quickLinks.title }</h3>
          <ul>
            <li>
              <a href="#about">{data.footer.quickLinks.about}</a>
            </li>
            <li>
              <a href="#skills">{data.footer.quickLinks.skills}</a>
            </li>
            <li>
              <a href="#projects">{data.footer.quickLinks.projects}</a>
            </li>
           
          </ul>
        </div>

        <div>
          <h3>{data.footer.contact}</h3>
          <p>
            Email:{" "}
            <a href="mailto:estexav0996@gmail.com">estexav0996@gmail.com</a>
          </p>
        </div>
      </div>

      <div className="credits">
        © 2024 Esteban Carrillo. {data.footer.copyright}.
      </div>
    </footer>
  );
}

export default Footer;
