import "../stylesheets/Skills.css";
import SkillBar from "./SkillBar";
import IconsContainer from "./IconsContainer";
import { useState } from "react";
import technologies from "../data/skillsDatabase";

function Skills({ className, trigger }) {
  const [item, setItem] = useState("");

  const changeIconStyles = (e) => {
    setItem(e);
  };

  return (
    <section className={className} id="skills">
      <div className="container section-container skills-container">
        <h1>Skills</h1>
        <div className="skills-items-container">
          {technologies.map((e) => (
            <SkillBar
              key={e.alt}
              text={e.name}
              percent={e.percentage}
              changeIconStyles={changeIconStyles}
            />
          ))}
        </div>
        <IconsContainer trigger={trigger} item={item}></IconsContainer>
      </div>
    </section>
  );
}

export default Skills;
