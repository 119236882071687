
import ProjectsItemContainer from "./ProjectsItemsContainer";
import "../stylesheets/Projects.css"
import { useSelector } from "react-redux";

function Projects({className,trigger}) {
  const data = useSelector(state => state.lang.data);

  if(!data.projects){return <section className={className} id="projects">Loading...</section> ;}
  return (
    <section className={className} id="projects">
      <div className="container section-container projects-container">
        <h1>{data.projects.title}</h1>
        <ProjectsItemContainer trigger={trigger} />
      </div>
    </section>
  );
}

export default Projects;
