import Project from "./Project";
import "../stylesheets/ProjectsItemsContainer.css";
import { projectsDb, projectsListsDb } from "../data/projectsDatabase";
import { useSelector } from "react-redux";

function ProjectsItemContainer({ trigger }) {
  const data = useSelector((state) => state.lang.data);

  if (!data.projects) {
    return <section className="projects-items-container">Loading...</section>;
  }
  return (
    <>
      <h2>{data.projects.type.computing.title}</h2>
      <div
        className={
          "projects-items-container" +
          (trigger ? " projects-items-container-animation" : "")
        }
      >
        {projectsDb.map((e, i) => (
          <Project
            key={"project" + i}
            src={e.src}
            title={data.projects.type.computing.projectsText[i].title}
            text={data.projects.type.computing.projectsText[i].text}
            url={e.url}
            techStack={e.techStack}
            buttonText={data.projects.type.computing.btnText}
          />
        ))}
      </div>
      <h2>{data.projects.type.electronics.title}</h2>
      <div
        className={
          "projects-items-container projects-items-container__right" +
          (trigger ? " projects-items-container-animation__right" : "")
        }
      >
        {projectsListsDb.map((e, i) => (
          <Project
            key={"projectList" + i}
            src={e.src}
            title={data.projects.type.electronics.projectsText[i].title}
            techStack={e.techStack}
            text={data.projects.type.electronics.projectsText[i].text}
            url={e.url}
            buttonText={data.projects.type.electronics.btnText}
          />
        ))}
      </div>
    </>
  );
}

export default ProjectsItemContainer;
