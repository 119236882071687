import colegeTitle from "../assets/certificates-images/titulo-colegio.jpg";
import instituteTitle from "../assets/certificates-images/titulo-instituto.jpg";
// import maschaCertificate from "../assets/certificates-images/certificado-mashca-bots.jpg";
// import innovaProject from "../assets/certificates-images/certificado-proyectos-inovadores.jpg";
import cssCertificate from "../assets/certificates-images/css-certificate-fcc.jpg";
import jsCertificate from "../assets/certificates-images/js-algotithms-and-data-structures.jpg";
import reactCertificate from "../assets/certificates-images/front-end-developer-libraries.jpg";

import "../stylesheets/Certificates.css";
import { useSelector } from "react-redux";

function Certificates({ className, trigger }) {

  const data = useSelector(state => state.lang.data);
  // if (!data.certificates) {
  //   return <section className={className}> Loading...</section>;
  // }
  return (
    <section className={className} id="certificates">
      <div className="container section-container certificates-container">
        <h1>{data.certificates?.title}</h1>
        <div className="certificates-items-container">
          <div
            className={
              "certificate-item" +
              (trigger ? " certificate-item-animation" : "")
            }
          >
            <img
              className="certificate-item-image"
              src={colegeTitle}
              alt="Colege certificate"
            />
          </div>

          <div
            className={
              "certificate-item" +
              (trigger ? " certificate-item-animation" : "")
            }
          >
            <img
              className="certificate-item-image"
              src={instituteTitle}
              alt="Institute certificate"
            />
          </div>

          <div
            className={
              "certificate-item" +
              (trigger ? " certificate-item-animation" : "")
            }
          >
            <img
              className="certificate-item-image"
              src={cssCertificate}
              alt="CSS Free code camp certificate"
            />
          </div>

          <div
            className={
              "certificate-item" +
              (trigger ? " certificate-item-animation" : "")
            }
          >
            <img
              className="certificate-item-image"
              src={jsCertificate}
              alt="javaScript Free code camp certificate"
            />
          </div>
          <div
            className={
              "certificate-item" +
              (trigger ? " certificate-item-animation" : "")
            }
          >
            <img
              className="certificate-item-image"
              src={reactCertificate}
              alt="Front end libraries React  Free code camp certificate"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Certificates;
