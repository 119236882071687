import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "",
  data: {},
};

export const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    changeLang: (state, action) => {
      state.lang = action.payload;
    },
    changeData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { changeLang, changeData } = langSlice.actions;
export default langSlice.reducer;
